/**
 * Type: ページ
 * What: ご来館される方へ
 */
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';

const pageTitle = 'ご来館される方へ';
const pageDescription =
  'E・ZO FUKUOKA（イーゾ フクオカ）にお越しのお客様へのお願いです。';
const pageSlug = 'rule';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline">
          <span>ご来館の皆様へ</span>
        </h3>
        <div className="inner-slim">
          <article>
            <p>
              全てのご来場のお客様が楽しく、安全でご安心頂けるように下記ルールにつきご理解とご協力をお願いいたします。
            </p>
          </article>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="inner-slim">
          <article>
            <h4 className="title is-4 has-text-align-left">
              1. 禁止行為について
            </h4>
            <p>
              館内では以下の行為について禁止しています。係員（警備員を含みます）の許可なく禁止行為を行った場合、又は係員の指示を聞き入れない場合、館内からの退去・私物の撤去等の措置を取ること、以降のご入館をお断りさせていただくことがございますので、ご了承願います。
            </p>
            <ol className="list-base">
              <li>指定場所以外での喫煙行為（電子タバコを含む）</li>
              <li>長時間にわたる無人での場所取り行為</li>
              <li>館内での座り込み・横になる行為</li>
              <li>
                エスカレーター・階段での危険な行為（エスカレーターの逆走、手摺り等をすべり台とする行為等）
              </li>
              <li>館内飲食エリア以外での食事</li>
              <li>動物へのえさやり</li>
              <li>館内禁止エリアへの飲食物、ビン、カン類の持ち込み</li>
              <li>館内へのペットの持ち込み（補助犬は除きます。）</li>
              <li>家庭ごみの持ち込み</li>
              <li>
                爆発物・鉄砲刀剣類・花火・爆竹・薬物・毒物劇物その他危険物の持ち込み（服用している場合を含みます。）
              </li>
              <li>粗暴行為（暴言、脅迫、恐喝、威嚇、暴力等）</li>
              <li>館内コンセントの使用</li>
              <li>植栽内への立ち入り・伐採行為</li>
              <li>立入禁止区域内への立ち入り</li>
              <li>
                通行妨害、プライバシー侵害等、他のお客様の迷惑になる態様での撮影、撮影禁止エリアでの撮影
              </li>
              <li>
                主催者の許可なく、音声・映像などをインターネット・その他メディアを通じて不特定多数の視聴が可能なものとして生配信または長時間の録画を配信するなど、記念撮影や個人の鑑賞目的以外の目的での撮影
              </li>
              <li>
                無許可での営業行為、入場券類の売買・贈与・転売などのダフ屋行為（ダフ屋行為の目的での徘徊を含みます。）、チラシの配布、アンケート、広告宣伝活動、録画撮影その他集会の開催
              </li>
              <li>
                館内の安全・風紀・秩序を乱し、他のお客様に迷惑を及ぼす行為
              </li>
              <li>別途館内に掲示されている禁止行為</li>
              <li>その他催事進行、施設運営管理の妨げとなる一切の行為</li>
              <li>
                係員または出演者等に対し必要以上につきまとったり、待ち伏せまたは監視したりする事で著しく業務の妨げになる行為
              </li>
              <li>
                施設・設備・その他第三者の物の損壊、毀損行為（いたずらを含みます。）及び窃取行為、入場券類、及び諸権利を有する証憑類の偽造行為、並びに他のお客様または従業員を盗撮する行為等、法令に反する行為
              </li>
            </ol>
          </article>
          <hr className="bdr" />
          <article>
            <h4 className="title is-4 has-text-align-left">
              2. チケットについて
            </h4>
            <ol className="list-base">
              <li>
                <a
                  href="https://ticket.e-zofukuoka.com/#/terms"
                  className="tx-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  E・ZOチケットシステムサービス利用規約
                </a>
                をご参照ください。
              </li>
              <li>
                各種チケットにつきましては、ご購入時の注意事項及びE・ZO公式サイト内記載の各施設の注意事項（E・ZO公式サイト内施設紹介ページや各施設の入場口に記載があります。）をご理解の上、ご利用ください。
              </li>
            </ol>
          </article>
          <hr className="bdr" />
          <article>
            <h4 className="title is-4 has-text-align-left">
              3. イベントについて
            </h4>
            <p>各イベント主催者が定めるルールを守ってください。</p>
          </article>
          <hr className="bdr" />
          <article>
            <h4 className="title is-4 has-text-align-left">
              4. コインロッカー・駐車場の利用について
            </h4>
            <ol className="list-base">
              <li>
                コインロッカーに掲示の貸ロッカー使用約款をご参照ください。
              </li>
              <li>
                BOSS E・ZO FUKUOKA専用の駐車場はございません。BOSS E・ZO
                FUKUOKAご利用のお客様は、ご利用金額に応じて駐車場割引サービスが適用となる、みずほPayPayドームの駐車場をご利用ください。
                <br />
                なお、ご利用の際は各駐車場の規則・注意事項等をご確認ください。
              </li>
            </ol>
          </article>
          <hr className="bdr" />
          <article>
            <h4 className="title is-4 has-text-align-left">
              5. 暴力団排除等について
            </h4>
            <p>
              暴力団、暴力団関係者等反社会的勢力の方々の
              施設利用を固くお断りしております。
            </p>
          </article>
          <hr className="bdr" />
          <article>
            <h4 className="title is-4 has-text-align-left has-text-align-left">
              6. ご協力のお願い
            </h4>
            <ol className="list-base">
              <li>ごみの分別回収にご協力をお願いいたします。</li>
              <li>
                BOSS E・ZO
                FUKUOKAでは、受動喫煙防止の観点から、分煙環境の整備に取り組んでおります。喫煙は所定の喫煙所でお願いいたします。
              </li>
              <li>
                数に限りがあるため、館内でお貸ししている車イスの長時間のご利用はお控えください。
              </li>
              <li>
                館内にてお客様ご自身の肖像、声が撮影、収録される場合がございます。予め下記詳細をご確認のうえご入館ください。
              </li>
            </ol>
            <p className="list-mark">
              <span className="attention">※</span>
              撮影、収録された音声および映像は、会場内外の上映用画面、印刷物、テレビ放送、インターネット配信、DVD等の各種媒体において、イベント中またはイベント後に使用される場合があります。撮影、収録された音声および映像を使用する場合、次の目的で使用いたします。
            </p>
            <ol className="list-base">
              <li>イベントの演出</li>
              <li>イベントの記録作成</li>
              <li>
                福岡ソフトバンクホークス株式会社およびグループ会社の企業広報活動
              </li>
              <li>関係企業の商品の宣伝活動</li>
              <li>商品販売・サービス提供</li>
            </ol>
          </article>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
